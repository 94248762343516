import React, { Component } from "react"
import 'moment/locale/nl';

class Plava extends Component {

    componentDidMount() {
        window.location.replace("http://plava.nl/");
    }

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        return (
            <>
            </>
        )
    }

}


export default Plava
